import React from "react";
export const ChevronDown = ({fill, size, height, width, ...props}) => {
  return (
    <svg
      fill="none"
      height={size || height || 24}
      viewBox="0 0 24 24"
      width={size || width || 24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m19.92 8.95-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
    </svg>
  );
};

export const House = ({fill, size, height, width, ...props}) => {
  const color = fill;

  return (
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#FFF" fill-rule="evenodd" clip-rule="evenodd"><path d="M2 16v6l-2-.014v-7.995l4.998-3.997 2.359 2.481 7.618-6.478 7.025 5.855v10.148h-2.991v-6h-4.009v6h-6.991v-6h-6.009zm5 5v1h-3.991v-1h3.991zm-3.991-2h3.991v1h-3.991v-1zm10-3h-2.009v3h2.009v-3zm-10 1h3.991v1h-3.991v-1zm11.985-15l9.006 7.67-1.328 1.493-7.678-6.489-7.569 6.456-1.427-1.465 8.996-7.665z"/></svg>
  );
};

export const Tint = ({fill, size, height, width, ...props}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" fill="#FFF" width="24" height="24" viewBox="0 0 24 24"><path d="M6.402 7.815l-3.594-3.594 1.415-1.414 3.594 3.594c-.538.402-1.013.878-1.415 1.414zm14.79-3.594l-16.969 16.97-1.414-1.414 5.035-5.036c-.528-.785-.844-1.721-.844-2.741 0-2.762 2.238-5 5-5 1.02 0 1.956.316 2.741.844l5.037-5.038 1.414 1.415zm-11.891 9.064l3.984-3.984c-.389-.19-.823-.301-1.285-.301-1.654 0-3 1.346-3 3 0 .462.111.896.301 1.285zm3.699-8.205v-5.08h-2v5.08c.328-.047.66-.08 1-.08s.672.033 1 .08zm-8 6.92c0-.34.033-.672.08-1h-5.08v2h5.08c-.047-.328-.08-.66-.08-1zm11.185 5.598l3.594 3.593 1.415-1.414-3.594-3.593c-.403.536-.879 1.012-1.415 1.414zm.629-6.878l-1.875 1.875c-.237 1.178-1.167 2.107-2.345 2.345l-1.874 1.874c.412.109.835.186 1.28.186 2.762 0 5-2.238 5-5 0-.445-.077-.869-.186-1.28zm2.106.28c.047.328.08.66.08 1s-.033.672-.08 1h5.08v-2h-5.08zm-7.92 7.92v5.08h2v-5.08c-.328.047-.66.08-1 .08s-.672-.033-1-.08z"/></svg>
  );
};

export const Mobile = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#FFF" fill-rule="evenodd" clip-rule="evenodd"><path d="M6 24h-2c-.552 0-1-.448-1-1v-1c-.53 0-1.039-.211-1.414-.586s-.586-.884-.586-1.414v-8c-.552 0-1-.448-1-1v-3c0-.552.448-1 1-1v-4c0-1.657 1.343-3 3-3h16c1.657 0 3 1.343 3 3v4c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1v8c0 .53-.211 1.039-.586 1.414s-.884.586-1.414.586v1c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-1h-10v1c0 .552-.448 1-1 1zm-1.5-7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm15 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-5 1h-5c-.276 0-.5.224-.5.5s.224.5.5.5h5c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm6.5-12.5c0-.276-.224-.5-.5-.5h-17c-.276 0-.5.224-.5.5v8.5s3.098 1 9 1 9-1 9-1v-8.5zm-5-3.5h-8v1h8v-1z"/></svg>
  );
};

export const Detail = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" fill="#FFF" width="24" height="24" viewBox="0 0 24 24"><path d="M16 6c-3.652 5.397-6 8.774-6 12.056 0 3.283 2.684 5.944 6 5.944s6-2.661 6-5.944c0-3.282-2.348-6.659-6-12.056zm-.021 3.84c.351.543.771 1.49.771 2.49 0 2.931-3 3.411-3 1.627 0-1.224 1.492-3.033 2.229-4.117zm-7.975 8.16c.021-3.273 1.818-6.373 4.648-10.61-1.047-2.069-2.64-4.417-4.652-7.39-3.652 5.397-6 8.774-6 12.056 0 3.283 2.684 5.944 6 5.944h.004zm-2.254-10.042c0-1.225 1.492-3.033 2.229-4.118.351.543.771 1.49.771 2.49 0 2.931-3 3.411-3 1.628z"/></svg>
  );
};

export const Shield = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" fill="#FFF" width="24" height="24" viewBox="0 0 24 24"><path d="M15.762 8.047l-4.381 4.475-2.215-2.123-1.236 1.239 3.451 3.362 5.619-5.715-1.238-1.238zm-3.762-5.503c2.5 1.805 4.555 2.292 7 2.416v9.575c0 3.042-1.686 3.827-7 7.107-5.309-3.278-7-4.065-7-7.107v-9.575c2.447-.124 4.5-.611 7-2.416zm0-2.544c-3.371 2.866-5.484 3-9 3v11.535c0 4.603 3.203 5.804 9 9.465 5.797-3.661 9-4.862 9-9.465v-11.535c-3.516 0-5.629-.134-9-3z"/></svg>
  );
};


export const Ceramic = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" fill="#FFF" width="24" height="24" viewBox="0 0 24 24"><path d="M20.422 8.516c-.178-3.232-3.031-5.777-6.432-5.491-1.087-1.24-2.693-2.025-4.49-2.025-3.172 0-5.754 2.443-5.922 5.516-2.033.359-3.578 2.105-3.578 4.206 0 2.362 1.949 4.278 4.354 4.278h1.326c.771 1.198 2.124 2 3.674 2h10.291c2.406 0 4.355-1.916 4.355-4.278 0-2.101-1.545-3.847-3.578-4.206zm-15.395 4.484h-.673c-1.297 0-2.354-1.021-2.354-2.278 0-2.118 2.104-2.597 3.488-2.513-.05-1.355.137-5.209 4.012-5.209.967 0 1.714.25 2.29.645-1.823.921-3.096 2.745-3.212 4.871-2.022.357-3.697 2.127-3.551 4.484zm14.618 2h-10.291c-1.297 0-2.354-1.021-2.354-2.278 0-2.118 2.104-2.597 3.488-2.513-.05-1.355.137-5.209 4.012-5.209 3.771 0 4.229 3.771 4.012 5.209 1.509-.105 3.488.437 3.488 2.513 0 1.257-1.057 2.278-2.355 2.278zm2.105 4.25c0 .689-.559 1.25-1.25 1.25s-1.25-.561-1.25-1.25.559-1.25 1.25-1.25 1.25.561 1.25 1.25zm-4 0c0 .689-.559 1.25-1.25 1.25s-1.25-.561-1.25-1.25.559-1.25 1.25-1.25 1.25.561 1.25 1.25zm-4 0c0 .689-.559 1.25-1.25 1.25s-1.25-.561-1.25-1.25.559-1.25 1.25-1.25 1.25.561 1.25 1.25zm-4 0c0 .689-.559 1.25-1.25 1.25s-1.25-.561-1.25-1.25.559-1.25 1.25-1.25 1.25.561 1.25 1.25zm2 2.5c0 .689-.559 1.25-1.25 1.25s-1.25-.561-1.25-1.25.559-1.25 1.25-1.25 1.25.561 1.25 1.25zm4.094 0c0 .689-.559 1.25-1.25 1.25s-1.25-.561-1.25-1.25.559-1.25 1.25-1.25 1.25.561 1.25 1.25zm3.906 0c0 .689-.559 1.25-1.25 1.25s-1.25-.561-1.25-1.25.559-1.25 1.25-1.25 1.25.561 1.25 1.25z"/></svg>
  );
};

export const Business = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#FFF" fill-rule="evenodd" clip-rule="evenodd"><path d="M1 24v-2h2v-4h-2l1.996-4h.004v-14h18v14h.004l1.996 4h-2v4h2v2h-22zm4-1h4v-4h-4v4zm14-4h-4v4h4v-4zm-5 0h-4v4h4v-4zm6.386-4h-16.772l-1 2h18.772l-1-2zm-13.386-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z"/></svg>
  );
};

export const Plus = ({
  fill = "currentColor",
  size,
  height,
  width,
  ...props
}) => {
  return (
<svg width="24" height="24" clip-rule="evenodd" fill="#FFF" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm-.747 9.25h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero"/></svg>
  );
};