import React from 'react'

const Map = () => {
  return (
    <div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12538.961965525723!2d-85.5845615!3d38.2159212!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8869a1e0d2ed0713%3A0xa69ec895b62407b3!2sSupreme%20Detail%20Studio%20%26%20Mobile%20Detailing!5e0!3m2!1sen!2sus!4v1705179108767!5m2!1sen!2sus" width="100%" height="500" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Map;